import React, { useEffect,useState,useRef } from 'react';
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo, LogoJsonLd } from 'gatsby-plugin-next-seo'
import tw from 'twin.macro'
import { A, Title, SubTitle } from '../components/styles'
import Layout from "../components/layout"
import Group from "../components/group"

// import Swiper JS
import SwiperCore, { Navigation,Controller,EffectFade,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


// import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade"
import "swiper/css/navigation"



SwiperCore.use([Navigation,Controller,EffectFade,Autoplay]);

export const pageQuery = graphql`
 query {
    allStrapiHome{
     edges {
      node {
        seo {
            metaTitle
            metaDescription
            shareImage {
              url
            }
        },
        banner {
            description
            title
            media {
                url
                localFile {
                    childImageSharp {
                      gatsbyImageData(width: 3200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
            link {
                url
            }
        }
        how {
            description
            title
            media {
                localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1888, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
            link {
                url
            }
        }
        slogan {
            cont
        }
      }
    }
   }

   allStrapiWorkDetails(sort: { fields: [created_at], order: DESC}){
    edges {
     node {
        created_at
       seo{
          shareImage {
              localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1888, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                  }
              }
             }
       }
       title
       tooltip
       hot
       recommend
       work_categories {
           title
       }
       link {
        url
       }
     }
   }
  }

  allStrapiStoriesDetails(sort: { fields: [created_at], order: DESC}){
    edges {
     node {
        created_at
       seo{
           shareImage {
               localFile {
                   childImageSharp {
                     gatsbyImageData(width: 1888, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                   }
               }
              }
        }
       tooltip
       title
       date
       hot
       recommend
       stories_category {
           title
       }
       link {
        url
       }
     }
   }
  }
 }`

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export default function IndexPage({location,data}) {
    const node = data.allStrapiHome.edges[0].node
    const workArr = data.allStrapiWorkDetails.edges
    const storiesArr = data.allStrapiStoriesDetails.edges
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const swiperRef = useRef()
    const videoParentRef = useRef();
 useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
              });
          }
        }, 0);
      }
    }
  }, []);
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <Swiper 
                ref={swiperRef}
                className='mt-[130px] lg:mt-[90px] banner'
                navigation={true}
                controller={{ control: secondSwiper }}
                onSwiper={setFirstSwiper}
                loop={true}
                autoplay={{
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                  }}
            >
                {
                    node.banner.map((n,i)=>{
                        return (
                            <SwiperSlide key={i}>
                            <a className="block w-full h-[calc(100vh-238px)] xs:h-[calc(100vh-268px)] overflow-hidden relative" href={n.link.url}><figure className="absolute w-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:h-full">{n.media.localFile.childImageSharp?<GatsbyImage className="w-full bg-light lg:h-full" image={getImage(n.media.localFile)} alt="banner"/>:<div className="w-full lg:h-full" ref={videoParentRef} dangerouslySetInnerHTML={{ __html: `<video loop muted autoplay playsinline preload="metadata" class="w-full lg:h-full object-cover" src="${n.media.url}"/>`}} />}</figure></a>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <Swiper
                controller={{ control: firstSwiper }}
                onSwiper={setSecondSwiper}
                effect={"fade"}
                loop={true}
            >
                {
                    node.banner.map((n,i,a)=>{
                        return (
                            <SwiperSlide className="bg-white" key={i}>
                                <div className="text-xl leading-snug flex items-start pt-8 min-h-[88px] sm:min-h-[138px] xs:min-h-[168px] relative overflow-hidden xl:flex-col">
                                    <h2 className="w-1/4 pr-2 font-bold xl:w-full xl:pb-4 xl:pr-14"><A href={n.link.url}>{n.title}</A></h2>
                                    <p className="pl-2 w-1/2 pr-[60px] xl:w-full xl:pl-0 xl:pr-0">{n.description}</p>
                                    <ul className="w-1/4 text-right xl:absolute xl:right-0">
                                        <li>{i+1} <span>/</span> {a.length}</li>
                                    </ul>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <p data-aos="fade-up" data-aos-offset="400" data-aos-duration="1000" className="text-[72px] leading-tight pt-[300px] pb-[200px] com-indent lg:py-[120px] xl:text-[56px] lg:text-[32px]">{node.slogan[1].cont}</p>
            <div className="pb-[300px] lg:pb-[120px]">
                <h2 data-aos="fade-up" data-aos-duration="600" className="text-[200px] leading-none pb-[95px] lg:pb-10 xl:text-[150px] lg:text-[72px]">Work highlights</h2>
                <div className="works-block lg:!grid-cols-1">
                {
                    workArr.map(({node},i)=>{
                        if(node.recommend && i<6){
                            return (
                                <Group dataAosDuration={600+i*400} cl="lg:!col-auto" key={i} node={node}/>
                            )
                        }
                    })
                }
                </div>
                <A href="/work" variant="more">Show more work</A>
            </div>
            <p data-aos="fade-up" data-aos-offset="400" data-aos-duration="1000" className="text-[72px] leading-tight pt-[100px] pb-[200px] lg:pb-[120px] com-indent xl:text-[56px] lg:text-[32px] lg:pt-0">{node.slogan[0].cont}</p>
            <div className="flex items-start lg:flex-col pb-[200px] lg:pb-[120px]">
                <h2 className="text-[56px] xl:text-[40px] w-1/4 sticky top-0 pt-[180px] pr-5 lg:w-full lg:static lg:pr-0 lg:mb-10 lg:pt-0">How it’s done</h2>
                <div className="w-3/4 lg:w-full">
                {
                    node.how.map((n,i)=>{
                        return (
                            <div onClick={()=>{window.location.href=n.link.url}} className={`flex items-center lg:flex-col group cursor-pointer ${i%2!==0?'flex-row-reverse':''} ${i<node.how.length-1?'mb-[120px]':''}`} key={i}>
                                <figure data-aos="fade-up" data-aos-duration="600" className={`w-[55.555556%] lg:w-full lg:pb-10 ${i%2!==0?'pl-[50px] lg:pl-0':'pr-[50px] lg:pr-0'}`}><GatsbyImage className="w-full" image={getImage(n.media.localFile)} alt="banner"/></figure>
                                <div className="w-[44.444444%] lg:w-full">
                                    <SubTitle className="pb-8">{n.title}</SubTitle>
                                    <p className="pb-8 text-xl">{n.description}</p>
                                    <A href={n.link.url} variant="more">Learn More</A>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
            <div className="pb-[200px] lg:pb-[120px]">
                <h2 data-aos="fade-up" data-aos-duration="600" className="text-[200px] leading-none pb-[95px] lg:pb-10 xl:text-[150px] lg:text-[72px]">Stories</h2>
                <div className="grid grid-cols-4 gap-8 pb-[95px] grid-list lg:grid-cols-2 sm:grid-cols-1">
                {
                    storiesArr.map(({node},i)=>{
                        if(node.recommend && i<4){
                            return (
                                <Group dataAosDuration={600+i*400} lg={true} key={i} node={node}/>
                            )
                        }
                    })
                }
                </div>
                <A href="/stories" variant="more">Show more stories</A>
            </div>
        </Layout>
    )
}

